import "core-js/modules/es6.function.name";
//
//
//
//
//
//
//
//
//
//
//
//
//

import pathToRegexp from 'path-to-regexp';
export default {
  data: function data() {
    return {
      levelList: null
    };
  },
  watch: {
    $route: function $route() {
      this.getBreadcrumb();
    }
  },
  created: function created() {
    this.getBreadcrumb();
  },
  methods: {
    getBreadcrumb: function getBreadcrumb() {
      // only show routes with meta.title
      var matched = this.$route.matched.filter(function (item) {
        return item.meta && item.meta.title;
      });
      var first = matched[0];
      if (first.name.indexOf('Create') !== -1) {
        if (first.name.indexOf('CreateUser') !== -1) {
          matched = [{
            path: '/user/index',
            meta: {
              title: '用户管理'
            }
          }].concat(matched);
        } else {
          matched = [{
            path: '/dashboard',
            meta: {
              title: '创建工单'
            }
          }].concat(matched);
        }
      } else if (first.name.indexOf('Details') !== -1) {
        matched = [{
          path: '/dashboard',
          meta: {
            title: '查看工单'
          }
        }].concat(matched);
      } else if (first.name.indexOf('ViewEditorUser') !== -1) {
        matched = [{
          path: '/user/index',
          meta: {
            title: '用户管理'
          }
        }].concat(matched);
      } else {
        if (!this.isDashboard(first)) {
          matched = [{
            path: '/dashboard',
            meta: {
              title: '主页'
            }
          }].concat(matched);
        }
      }
      this.levelList = matched.filter(function (item) {
        return item.meta && item.meta.title && item.meta.breadcrumb !== false;
      });
    },
    isDashboard: function isDashboard(route) {
      var name = route && route.name;
      if (!name) {
        return false;
      }
      return name.trim().toLocaleLowerCase() === 'Dashboard'.toLocaleLowerCase();
    },
    pathCompile: function pathCompile(path) {
      // To solve this problem https://github.com/PanJiaChen/vue-element-admin/issues/561
      var params = this.$route.params;
      var toPath = pathToRegexp.compile(path);
      return toPath(params);
    },
    handleLink: function handleLink(item) {
      var redirect = item.redirect,
        path = item.path;
      if (path === '/workorder/addpointsorderdetails' || path === '/workorder/exchangecoupondetails') {
        var code = this.$route.query.workCode;
        var query = {
          workCode: code
        };
        this.$router.push({
          path: redirect,
          query: query
        });
        return;
      }
      if (redirect) {
        this.$router.push(redirect);
        return;
      }
      this.$router.push(this.pathCompile(path));
    }
  }
};