import "core-js/modules/es6.function.name";
var getters = {
  sidebar: function sidebar(state) {
    return state.app.sidebar;
  },
  device: function device(state) {
    return state.app.device;
  },
  token: function token(state) {
    return state.user.token;
  },
  department: function department(state) {
    return state.user.department;
  },
  departmentCode: function departmentCode(state) {
    return state.user.departmentCode;
  },
  name: function name(state) {
    return state.user.name;
  },
  roleName: function roleName(state) {
    return state.user.roleName;
  },
  permission_routes: function permission_routes(state) {
    return state.permission.routes;
  },
  type: function type(state) {
    return state.user.type;
  },
  asToken: function asToken(state) {
    return state.msal.aadInfo.adToken;
  },
  tokenRes: function tokenRes(state) {
    return state.msal.aadInfo.tokenRes;
  }
};
export default getters;