import request from '@/utils/request';
export function login(data) {
  return request({
    url: '/crm/login',
    method: 'post',
    data: data
  });

  // return request({
  //   url: '/crm/vue-admin-template/user/login',
  //   method: 'post',
  //   data
  // });
}
export function getInfo(data) {
  return request({
    url: '/crm/userinfo',
    method: 'post',
    data: data
  });
}
export function logout() {
  return request({
    url: '/crm/logout',
    method: 'get'
  });
}
export function resetPwd(data) {
  return request({
    url: '/crm/modifypassword',
    method: 'post',
    data: data
  });
}
export function verificationCode() {
  return request({
    url: '/crm/verificationcode',
    method: 'get'
  });
}
export function getMessageCode(data) {
  return request({
    url: '/crm/getmessagecode',
    method: 'post',
    data: data
  });
}
export function adGetInfo(data) {
  return request({
    url: '/crm/userLoginByAzure',
    method: 'get',
    data: data
  });
}