import Cookies from 'js-cookie';
var TokenKey = 'ksxt_token';
var SidebarKey = 'sidebarStatus';
var AccountKey = 'ksxt_user';
var firstLoginKey = 'firstLogin';
var lastLoginName = 'lastLoginUser'; // 针对浏览器关闭或标签关闭且token未失效重新打开浏览器或标签

export function getToken() {
  return Cookies.get(TokenKey);
}
export function setToken(token, time) {
  // token0.5小时后失效，按UTC时间
  if (time) {
    return Cookies.set(TokenKey, token, {
      expires: new Date(new Date() * 1 + time * 60 * 60 * 1000)
    });
  }
  return Cookies.set(TokenKey, token);
}
export function removeToken() {
  if (Cookies.get(SidebarKey)) {
    Cookies.remove(SidebarKey);
  }
  return Cookies.remove(TokenKey);
}
export function getUserName() {
  return Cookies.get(AccountKey);
}
export function setUserName(name) {
  return Cookies.set(AccountKey, name);
}
export function removeUserName() {
  if (Cookies.get(SidebarKey)) {
    Cookies.remove(SidebarKey);
  }
  return Cookies.remove(AccountKey);
}
export function getFirstLogin() {
  return Cookies.get(firstLoginKey);
}
export function setFirstLogin(val) {
  return Cookies.set(firstLoginKey, val);
}
export function removeFirstLogin() {
  return Cookies.remove(firstLoginKey);
}
export function getLastUser() {
  return localStorage.getItem(lastLoginName);
}
export function setLastUser(name) {
  return localStorage.setItem(lastLoginName, name);
}
export function removeLastUser() {
  return localStorage.removeItem(lastLoginName);
}