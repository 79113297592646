import Vue from 'vue';
import Router from 'vue-router';
var RouteView = {
  name: 'RouteView',
  data: function data() {
    return {
      pageLoading: undefined
    };
  },
  destroyed: function destroyed() {
    this.pageLoading && this.pageLoading.close();
  },
  created: function created() {
    this.pageLoading = this.$loading();
  },
  render: function render(h) {
    return h('router-view');
  }
};
Vue.use(Router);

/* Layout */
import Layout from '@/layout';

/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
export var constantRoutes = [{
  name: 'Login',
  path: '/login',
  component: function component() {
    return import('@/views/login/index');
  },
  hidden: true
}, {
  path: '/response',
  name: 'response',
  component: function component() {
    return import('@/views/response/index');
  },
  hidden: true
}, {
  path: '/404',
  component: function component() {
    return import('@/views/404');
  },
  hidden: true
}, {
  path: '/',
  component: Layout,
  redirect: '/dashboard',
  name: 'WorkOrder',
  // meta: { title: '主页', icon: 'dashboard' },
  children: [{
    path: 'dashboard',
    name: 'Dashboard',
    component: function component() {
      return import('@/views/workorder/index');
    },
    meta: {
      title: '主页',
      icon: 'dashboard'
    }
  }]
}, {
  path: '/dashboard',
  component: Layout,
  children: [{
    path: 'resetpwd',
    name: 'ResetPwd',
    component: function component() {
      return import('@/views/ResetPwd');
    },
    meta: {
      title: '修改密码',
      icon: 'password'
    }
  }]
},
// AWS负载均衡心跳检测URL
{
  path: '/healthcheck',
  component: function component() {
    return import('@/views/HealthCheck');
  },
  hidden: true
}, {
  path: '/workorder/addpointsorder/healthcheck',
  component: function component() {
    return import('@/views/HealthCheck');
  },
  hidden: true
}];

/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
 */
export var asyncRoutes = [{
  path: '/user',
  component: Layout,
  children: [{
    path: 'index',
    name: 'User',
    component: function component() {
      return import('@/views/user/index');
    },
    meta: {
      title: '用户管理',
      icon: 'group',
      roleName: [100000002, 100000003]
    }
  }]
}, {
  path: '/workorder',
  component: Layout,
  redirect: '/dashboard',
  children: [{
    path: 'addpointsorder',
    name: 'Bridge',
    redirect: 'add',
    // 空文件用于显示面包屑导航
    component: function component() {
      return import('@/views/workorder/bridge');
    },
    meta: {
      title: '非会员单增减积分',
      icon: '',
      roleName: [100000000, 100000003]
    },
    children: [{
      path: 'add',
      name: 'CreateNonMemberAddPoints',
      component: function component() {
        return import('@/views/workorder/CreateNonMemberAddPoints');
      },
      meta: {
        title: '新建',
        icon: ''
      }
    }, {
      path: 'orderexample',
      name: 'OrderExample',
      component: function component() {
        return import('@/views/workorder/OrderExample');
      },
      meta: {
        title: '查看示例',
        icon: ''
      }
    }]
  }, {
    path: 'addpointsorderdetails',
    name: 'editBridge',
    redirect: 'edit',
    // 空文件用于显示面包屑导航
    component: function component() {
      return import('@/views/workorder/bridge');
    },
    meta: {
      title: '非会员单增减积分',
      icon: ''
    },
    children: [{
      path: 'edit',
      name: 'NonMemberAddPointsDetails',
      component: function component() {
        return import('@/views/workorder/NonMemberAddPointsDetails');
      },
      meta: {
        title: '查看',
        icon: ''
      }
    }, {
      path: 'orderexample',
      name: 'OrderDetailsExample',
      component: function component() {
        return import('@/views/workorder/OrderDetailsExample');
      },
      meta: {
        title: '查看示例',
        icon: ''
      }
    }]
  }, {
    path: 'addmember',
    name: 'CreateOrActivateMember',
    component: function component() {
      return import('@/views/workorder/CreateOrActivateMember');
    },
    meta: {
      title: '新建会员',
      icon: '',
      roleName: [100000000, 100000003]
    }
  }, {
    path: 'addmemberdetails',
    name: 'ActivateMemberDetails',
    component: function component() {
      return import('@/views/workorder/CreateOrActivateMemberDetails');
    },
    meta: {
      title: '新建会员',
      icon: ''
    }
  }, {
    path: 'addother',
    name: 'CreateOtherTypes',
    component: function component() {
      return import('@/views/workorder/CreateOtherTypes');
    },
    meta: {
      title: '其他',
      icon: '',
      roleName: [100000000, 100000003]
    }
  }, {
    path: 'addotherdetails',
    name: 'OtherTypesDetails',
    component: function component() {
      return import('@/views/workorder/OtherTypesDetails');
    },
    meta: {
      title: '其他',
      icon: ''
    }
  }, {
    path: 'cancelMember',
    name: 'CancelMember',
    component: function component() {
      return import('@/views/workorder/CancelMember');
    },
    meta: {
      title: '会员注销',
      icon: '',
      roleName: [100000000, 100000003]
    }
  }, {
    path: 'cancelMemberDetails',
    name: 'CancelMemberDetails',
    component: function component() {
      return import('@/views/workorder/CancelMemberDetails');
    },
    meta: {
      title: '会员注销',
      icon: ''
    }
  }, {
    path: 'addmodifyphoneormergemember',
    name: 'CreateModifyPhoneOrMergeMember',
    component: function component() {
      return import('@/views/workorder/CreateModifyPhoneOrMergeMember');
    },
    meta: {
      title: '修改会员手机号/合并会员账号',
      icon: '',
      roleName: [100000000, 100000003]
    }
  }, {
    path: 'modifyphoneormergememberdetails',
    name: 'ModifyPhoneOrMergeMemberDetails',
    component: function component() {
      return import('@/views/workorder/ModifyPhoneOrMergeMemberDetails');
    },
    meta: {
      title: '修改会员手机号/合并会员账号',
      icon: ''
    }
  }, {
    path: 'addexchangecoupon',
    name: 'exchangeBridge',
    redirect: 'add',
    // 空文件用于显示面包屑导航
    component: function component() {
      return import('@/views/workorder/bridge');
    },
    meta: {
      title: '门店换货补券',
      icon: '',
      roleName: [100000000, 100000003]
    },
    children: [{
      path: 'add',
      name: 'CreateExchangeOrReissueCoupon',
      component: function component() {
        return import('@/views/workorder/CreateExchangeOrReissueCoupon');
      },
      meta: {
        title: '新建',
        icon: ''
      }
    }, {
      path: 'orderexample',
      name: 'ExchangeExample',
      component: function component() {
        return import('@/views/workorder/OrderExample');
      },
      meta: {
        title: '查看示例',
        icon: ''
      }
    }]
  }, {
    path: 'exchangecoupondetails',
    name: 'exchangeEditBridge',
    redirect: 'edit',
    // 空文件用于显示面包屑导航
    component: function component() {
      return import('@/views/workorder/bridge');
    },
    meta: {
      title: '门店换货补券',
      icon: ''
    },
    children: [{
      path: 'edit',
      name: 'ExchangeOrReissueCouponDetails',
      component: function component() {
        return import('@/views/workorder/ExchangeOrReissueCouponDetails');
      },
      meta: {
        title: '查看',
        icon: ''
      }
    }, {
      path: 'orderexample',
      name: 'ExchangeDetailsExample',
      component: function component() {
        return import('@/views/workorder/OrderDetailsExample');
      },
      meta: {
        title: '查看示例',
        icon: ''
      }
    }]
  }, {
    path: 'cancelMember',
    name: 'CancelMember',
    component: function component() {
      return import('@/views/workorder/CancelMember');
    },
    meta: {
      title: '会员注销',
      icon: '',
      roleName: [100000000, 100000003]
    }
  }, {
    path: 'cancelMemberDetails',
    name: 'CancelMemberDetails',
    component: function component() {
      return import('@/views/workorder/CancelMemberDetails');
    },
    meta: {
      title: '会员注销详情',
      icon: ''
    }
  }, {
    path: 'addEcommerceReturnCoupon',
    name: 'CreateEcommerceReturnCoupon',
    component: function component() {
      return import('@/views/workorder/CreateEcommerceReturnCoupon');
    },
    meta: {
      title: '电商退货退券',
      icon: '',
      roleName: [100000000, 100000003]
    }
  }, {
    path: 'ecommerceReturnCouponDetails',
    name: 'ecommerceReturnCouponDetails',
    component: function component() {
      return import('@/views/workorder/EcommerceReturnCouponDetails');
    },
    meta: {
      title: '电商退货退券',
      icon: ''
    }
  }, {
    path: 'memberunsubscribe/add',
    name: 'CreateMemberUnsubscribe',
    component: function component() {
      return import('@/views/workorder/memberUnsubscribe/Create');
    },
    meta: {
      title: '退订',
      icon: '',
      roleName: [100000000, 100000003]
    }
  }, {
    path: 'memberunsubscribe/details',
    name: 'MemberUnsubscribeTypesDetails',
    component: function component() {
      return import('@/views/workorder/memberUnsubscribe/Details');
    },
    meta: {
      title: '退订',
      icon: ''
    }
  }, {
    path: 'addCRMQueryException',
    name: 'AddCRMQueryException',
    component: function component() {
      return import('@/views/workorder/CreateCRMQueryException');
    },
    meta: {
      title: 'CRM异常反馈',
      icon: '',
      roleName: [100000000, 100000003]
    }
  }, {
    path: 'CRMQueryExceptionDetails',
    name: 'CRMQueryExceptionDetails',
    component: function component() {
      return import('@/views/workorder/CRMQueryExceptionDetails');
    },
    meta: {
      title: 'CRM异常反馈',
      icon: ''
    }
  }],
  hidden: true
}, {
  path: '/usermanagement',
  component: Layout,
  name: 'UserManagement',
  children: [{
    path: 'createuser',
    name: 'CreateUser',
    component: function component() {
      return import('@/views/user/CreateUser');
    },
    meta: {
      title: '新建用户',
      icon: ''
    }
  }, {
    path: 'vieweditoruser',
    name: 'ViewEditorUser',
    component: function component() {
      return import('@/views/user/ViewEditorUser');
    },
    meta: {
      title: '查看用户',
      icon: ''
    }
  }],
  hidden: true
}, {
  path: '/report',
  component: Layout,
  children: [{
    path: 'index',
    name: 'Report',
    component: function component() {
      return import('@/views/report/index');
    },
    meta: {
      title: '报表',
      icon: 'report',
      roleName: [100000002]
    }
  }]
},
// 404 page must be placed at the end !!!
{
  path: '*',
  redirect: '/404',
  hidden: true
}];
var createRouter = function createRouter() {
  return new Router({
    mode: 'history',
    // require service support
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: constantRoutes
  });
};
var router = createRouter();

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}
export default router;