import Vue from 'vue';
import Vuex from 'vuex';
import getters from "./getters";
import app from "./modules/app";
import permission from "./modules/permission";
import settings from "./modules/settings";
import user from "./modules/user";
import msal from "./modules/msal";
Vue.use(Vuex);
var store = new Vuex.Store({
  modules: {
    app: app,
    permission: permission,
    settings: settings,
    user: user,
    msal: msal
  },
  getters: getters
});
export default store;