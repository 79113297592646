import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import _defineProperty from "/home/jenkins/work/workspace/_complaint-ui-service-ci_release/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import Logo from "./Logo";
import SidebarItem from "./SidebarItem";
import _variables from '@/styles/variables.scss';
export default {
  components: {
    SidebarItem: SidebarItem,
    Logo: Logo
  },
  data: function data() {
    return {
      filterTypes: ''
    };
  },
  computed: _objectSpread(_objectSpread({}, mapGetters(['permission_routes', 'sidebar'])), {}, {
    activeMenu: function activeMenu() {
      var route = this.$route;
      var meta = route.meta,
        path = route.path;
      // if set path, the sidebar will highlight the path you set
      if (meta.activeMenu) {
        return meta.activeMenu;
      }
      return path;
    },
    showLogo: function showLogo() {
      return this.$store.state.settings.sidebarLogo;
    },
    variables: function variables() {
      return _variables;
    },
    isCollapse: function isCollapse() {
      return this.$store.state.app.device !== 'mobile' ? false : !this.sidebar.opened;
    }
  }),
  watch: {
    $route: function $route(to) {
      // const arr = Object.getOwnPropertyNames(route.query);
      if (to.path === '/dashboard') {
        var workPaginationOpt = sessionStorage.getItem('workPaginationOpt');
        this.filterTypes = workPaginationOpt ? JSON.parse(workPaginationOpt).type : '-1';
        // if (arr.length === 0) {

        // }
      } else {
        this.filterTypes = '';
      }
    }
  },
  mounted: function mounted() {
    if (this.$route.path === '/dashboard') {
      this.filterTypes = sessionStorage.getItem('workPaginationOpt') ? JSON.parse(sessionStorage.getItem('workPaginationOpt')).type : '-1';
    }
  }
};