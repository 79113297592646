import * as msal from '@azure/msal-browser';

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */

export var msalConfig = process.env.VUE_APP_AD_OPEN === 'true' ? {
  auth: {
    clientId: process.env.VUE_APP_MSAL_CLIENT_ID,
    // This is the ONLY mandatory field that you need to supply.
    authority: process.env.VUE_APP_MSAL_LOGIN_URI + process.env.VUE_APP_MSAL_TENANT_ID,
    // Defaults to "https://login.microsoftonline.com/common"
    redirectUri: process.env.VUE_APP_MSAL_REDIRECT_URI,
    // You must register this URI on Azure Portal/App Registration. Defaults to window.location.href
    // redirectUri: process.env.VUE_APP_MSAL_REDIRECT_URI, // You must register this URI on Azure Portal/App Registration. Defaults to window.location.href
    postLogoutRedirectUri: process.env.VUE_APP_MSAL_LOGOUT_REDIRECT_URI,
    navigateToLoginRequestUrl: false
  },
  cache: {
    cacheLocation: 'sessionStorage',
    // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO.
    storeAuthStateInCookie: false // If you wish to store cache items in cookies as well as browser cache, set this to "true".
  },
  system: {
    loggerOptions: {
      loggerCallback: function loggerCallback(level, message, containsPii) {
        if (containsPii) {
          return;
        }
        switch (level) {
          case msal.LogLevel.Error:
            console.error(message);
            return;
          case msal.LogLevel.Info:
            console.info(message);
            return;
          case msal.LogLevel.Verbose:
            console.debug(message);
            return;
          case msal.LogLevel.Warning:
            console.warn(message);
            return;
        }
      }
    }
  }
} : {};

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export var loginRequest = {
  scopes: ['openid', 'profile', 'User.Read']
  // scopes: ['User.Read']
};
export var tokenRequest = {
  scopes: ['User.Read', 'Mail.Read'],
  forceRefresh: false // Set this to "true" to skip a cached token and go to the server to get a new token
};
export var graphConfig = {
  graphMeEndpoint: process.env.VUE_APP_MSAL_GRAPH_URI,
  graphMailEndpoint: process.env.VUE_APP_MSAL_GRAPH_URI + '/messages'
};