import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.regexp.constructor";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.sort";
import "core-js/modules/es6.array.find";
import "core-js/modules/es6.regexp.split";
import "core-js/modules/es7.string.pad-start";
import "core-js/modules/es6.regexp.replace";
import "core-js/modules/es6.regexp.to-string";
import _typeof from "/home/jenkins/work/workspace/_complaint-ui-service-ci_release/node_modules/@babel/runtime-corejs2/helpers/esm/typeof.js";
var moment = require('moment');
/**
 * Created by PanJiaChen on 16/11/18.
 */

/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string | null}
 */
export function parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null;
  }
  var format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}';
  var date;
  if (_typeof(time) === 'object') {
    date = time;
  } else {
    if (typeof time === 'string' && /^[0-9]+$/.test(time)) {
      time = parseInt(time);
    }
    if (typeof time === 'number' && time.toString().length === 10) {
      time = time * 1000;
    }
    date = new Date(time);
  }
  var formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  };
  var time_str = format.replace(/{([ymdhisa])+}/g, function (result, key) {
    var value = formatObj[key];
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value];
    }
    return value.toString().padStart(2, '0');
  });
  return time_str;
}

/**
 * @param {number} time
 * @param {string} option
 * @returns {string}
 */
export function formatTime(time, option) {
  if (('' + time).length === 10) {
    time = parseInt(time) * 1000;
  } else {
    time = +time;
  }
  var d = new Date(time);
  var now = Date.now();
  var diff = (now - d) / 1000;
  if (diff < 30) {
    return '刚刚';
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + '分钟前';
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + '小时前';
  } else if (diff < 3600 * 24 * 2) {
    return '1天前';
  }
  if (option) {
    return parseTime(time, option);
  } else {
    return d.getMonth() + 1 + '月' + d.getDate() + '日' + d.getHours() + '时' + d.getMinutes() + '分';
  }
}

/**
 * @param {string} url
 * @returns {Object}
 */
export function param2Obj(url) {
  var search = url.split('?')[1];
  if (!search) {
    return {};
  }
  return JSON.parse('{"' + decodeURIComponent(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"').replace(/\+/g, ' ') + '"}');
}

/**
 * @param {string} file
 * @returns {base64}
 */
export function compressImage(file, isGt1M) {
  return new Promise(function (resolve, reject) {
    // 创建一个HTML5的FileReader对象
    var reader = new FileReader();
    // 创建一个img对象
    var img = new Image();
    var canvas = document.createElement('canvas');
    var context = canvas.getContext('2d');
    if (file) {
      reader.readAsDataURL(file);
    }
    var newUrl;
    reader.onload = function (e) {
      if (!isGt1M) {
        newUrl = e.target.result;

        // console.log('图片SRC：', newUrl);
      }
      img.src = e.target.result;

      // base64地址图片加载完毕后
      img.onload = function () {
        var MAX_WIDTH = 1024;
        var MAX_HEIGHT = 768;
        var width = img.width;
        var height = img.height;
        if (width > height) {
          if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width;
            width = MAX_WIDTH;
          }
        } else {
          if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height;
            height = MAX_HEIGHT;
          }
        }
        canvas.width = width;
        canvas.height = height;
        context.mozImageSmoothingEnabled = false;
        context.webkitImageSmoothingEnabled = false;
        context.msImageSmoothingEnabled = false;
        context.imageSmoothingEnabled = false;
        context.drawImage(img, 0, 0, width, height);
        newUrl = canvas.toDataURL('image/jpeg', 0.9);
        resolve(newUrl);
        // console.log(newUrl);

        /**
        // 1.去掉base64固定前缀字符
        let str = newUrl.substring(22);
        // 2.去掉等号
        const equalIndex = str.indexOf('=');
        if (str.indexOf('=') > 0) {
          str = str.substring(0, equalIndex);
        }
        // 原来的字符流大小，单位为字节
        const strLength = str.length;
        // 3.计算后得到的文件流大小，单位为字节
        const size = (strLength - (strLength / 8) * 2) / 1024 / 1024;
         // console.log('压缩后的base64文件流大小：', size.toFixed(2) + 'Mb');
        */
      };
    };
  });
}

/**
 * @param {string} roleName
 * @returns {boolean}
 */
export function hiddenForUser(roleName) {
  var search = [100000000, 100000003];
  var isShow = search.find(function (item) {
    return roleName.indexOf(item) !== -1;
  });
  return isShow !== undefined;
}

/**
 * @param {string} roleName
 * @returns {boolean}
 */
export function hiddenUserManagerForRoleName(roleName) {
  var search = [100000002, 100000003];
  var isShow = search.find(function (item) {
    return roleName.indexOf(item) !== -1;
  });
  return isShow !== undefined;
}

/**
 * @param {string} roleName
 * @returns {boolean}
 */
export function hiddenReportForRoleName(roleName) {
  var search = [100000002];
  var isShow = search.find(function (item) {
    return roleName.indexOf(item) !== -1;
  });
  return isShow !== undefined;
}

/**
 * @param {string} roleName
 * @returns {boolean}
 */
export function showApprovalForRoleName(roleName) {
  var search = [100000001, 100000002];
  var isShow = search.find(function (item) {
    return roleName.indexOf(item) !== -1;
  });
  return isShow !== undefined;
}

/**
 * @param {string} obj
 * @returns {obj}
 */
export function resetObjInit(obj) {
  for (var key in obj) {
    obj[key] = '';
  }
  return obj;
}

/**
 * @param {Array} arr
 * @param {string} key
 * @returns {Array}
 */
export function sortKey(arr, key) {
  if (arr && arr.length) {
    return arr.sort(function (a, b) {
      var x = a[key];
      var y = b[key];
      return x > y ? -1 : x < y ? 1 : 0;
    });
  } else {
    return [];
  }
}

/**
 * @param {string} roleName
 * @returns {boolean}
 */
export function changeStatus(status) {
  var search = [100000004, 100000005];
  return search.indexOf(status) !== -1;
}

/**
 * @param {object} arrayObj
 * @returns {array}
 */
export function jsonToArray(arrayObj) {
  var arr = [];
  if (!arrayObj.length) {
    return arr;
  }
  arrayObj.forEach(function (el) {
    arr.push(el.url);
  });
  return arr;
}

/**
 * @param {string} date
 * @returns {string}
 */
export function add8Hours(date) {
  date = moment.utc(date).toDate();
  return moment(date).format('YYYY-MM-DD HH:mm:ss');
}

/**
 * @param {string} date
 * @returns {string}
 */
export function minus8Hours(date) {
  date = moment(date).utc();
  return moment(date).format('YYYY-MM-DD HH:mm:ss');
}

/**
 * @param {Function} func
 * @param {number} wait
 * @param {boolean} immediate
 * @return {*}
 */
export function debounce(func, wait, immediate) {
  var timeout, args, context, timestamp, result;
  var _later = function later() {
    // 据上一次触发时间间隔
    var last = +new Date() - timestamp;

    // 上次被包装函数被调用时间间隔 last 小于设定时间间隔 wait
    if (last < wait && last > 0) {
      timeout = setTimeout(_later, wait - last);
    } else {
      timeout = null;
      // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用
      if (!immediate) {
        result = func.apply(context, args);
        if (!timeout) context = args = null;
      }
    }
  };
  return function () {
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }
    context = this;
    timestamp = +new Date();
    var callNow = immediate && !timeout;
    // 如果延时不存在，重新设定延时
    if (!timeout) timeout = setTimeout(_later, wait);
    if (callNow) {
      result = func.apply(context, args);
      context = args = null;
    }
    return result;
  };
}

/**
 * @param {string} d1
 * @param {string} d2
 * @returns {boolean}
 */
export function compareDate(d1, d2) {
  var reg = new RegExp(/(\d{4}-\d{2})/);
  d1 = d1.replace(reg, '$1-01');
  d2 = d2.replace(reg, '$1-01');
  reg = new RegExp('-', 'g');
  return new Date(d1.replace(reg, '/')) > new Date(d2.replace(reg, '/'));
}

/**
 * @param {string} str
 * @returns {Object}
 */
export function jsonStr2JSON(str) {
  try {
    return JSON.parse(str);
  } catch (e) {
    console.log(e);
    return null;
  }
}

/**
 * @param {Object} obj
 * @returns {array}
 * 返回的数组长度固定为2，第一个元素为总的属性个数，第二个元素为出现为空的属性个数（0表示属性都有值）
 */
export function paramsValidate(obj) {
  var index = 0;
  var arr = Object.keys(obj);

  // console.log(arr.length);
  for (var key in obj) {
    if (obj[key] !== '0' && !obj[key]) {
      // console.log(obj[key]);
      index++;
    }
  }
  return [arr.length, index];
}