/**
 * Created by PanJiaChen on 16/11/18.
 */

/**
 * @param {string} path
 * @returns {Boolean}
 */
export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path);
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validUsername(str) {
  return true;
  // return /^[Aa][Dd][Ss][Cc][Ll][Dd]\\/.test(str);
}

/**
 * Added by FengCsai on 2020/02/28.
 */

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validWorkCode(str) {
  return /^Task([0-9]{3}[1-9]|[0-9]{2}[1-9][0-9]{1}|[0-9]{1}[1-9][0-9]{2}|[1-9][0-9]{3})(((0[13578]|1[02])(0[1-9]|[12][0-9]|3[01]))|((0[469]|11)(0[1-9]|[12][0-9]|30))|(02(0[1-9]|[1][0-9]|2[0-8])))\d{7}/.test(str);
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validPhone(str) {
  return /^(?:(?:\+|00)86)?1[3-9]\d{9}$/.test(str);
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validMoney(str) {
  return /(^-?[1-9]\d*$)|(^[1-9]\d*\.\d*|0\.\d*[1-9]\d*$)/.test(str);
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validNumber(str) {
  return /^\d{11,12}$/.test(str);
}
/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validPositiveNumber(str) {
  return /(^[1-9]\d*$)|(^[1-9]\d*\.\d*|0\.\d*[1-9]\d*$)/.test(str);
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validPwd(str) {
  return /^(?![A-Z]+$)(?![a-z]+$)(?!\d+$)\S{8,}$/.test(str);
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validEmail(str) {
  return /^[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?$/.test(str);
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validAccount(str) {
  return /^[a-zA-Z0-9]{1,20}$/.test(str);
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validCode(str) {
  return /^[0-9]{4}$/.test(str);
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validSMS(str) {
  return /^[0-9]{6}$/.test(str);
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validECCSD(str) {
  return /^ECCSD.*$/.test(str);
}