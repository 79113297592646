var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "navbar" },
    [
      _c("hamburger", {
        staticClass: "hamburger-container",
        attrs: {
          "is-active": _vm.sidebar.opened,
          "is-user": _vm.device !== "mobile",
        },
        on: { toggleClick: _vm.toggleSideBar },
      }),
      _c("breadcrumb", { staticClass: "breadcrumb-container" }),
      _c(
        "div",
        { staticClass: "right-menu" },
        [
          _c(
            "el-dropdown",
            { staticClass: "avatar-container", attrs: { trigger: "click" } },
            [
              _c("div", { staticClass: "avatar-wrapper" }, [
                _c("i", { staticClass: "el-icon-user-solid" }),
                _c("span", [_vm._v(_vm._s(_vm.name))]),
                _c("i", { staticClass: "el-icon-caret-bottom" }),
              ]),
              _c(
                "el-dropdown-menu",
                {
                  staticClass: "user-dropdown",
                  attrs: { slot: "dropdown" },
                  slot: "dropdown",
                },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/dashboard/resetpwd" } },
                    [_c("el-dropdown-item", [_vm._v("修改密码")])],
                    1
                  ),
                  _c(
                    "router-link",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.getHiddenUserManagerForRoleName,
                          expression: "getHiddenUserManagerForRoleName",
                        },
                      ],
                      attrs: { to: "/user/index" },
                    },
                    [_c("el-dropdown-item", [_vm._v("用户管理")])],
                    1
                  ),
                  _c(
                    "router-link",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.getHiddenReportForRoleName,
                          expression: "getHiddenReportForRoleName",
                        },
                      ],
                      attrs: { to: "/report/index" },
                    },
                    [_c("el-dropdown-item", [_vm._v("查看报表")])],
                    1
                  ),
                  _c(
                    "el-dropdown-item",
                    {
                      attrs: { divided: _vm.getHiddenReportForRoleName },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.logout($event)
                        },
                      },
                    },
                    [
                      _c("span", { staticStyle: { display: "block" } }, [
                        _vm._v("退出账号"),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }