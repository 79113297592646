import "core-js/modules/es6.function.name";
import storage from 'store';
import { AD_TOKEN } from '@/store/mutation-types';
import { login as _login, getInfo as _getInfo, logout as _logout } from '@/api/user';
import { getToken, setToken, removeToken, getUserName, setUserName, removeUserName, removeFirstLogin, setLastUser, getLastUser, removeLastUser } from '@/utils/auth';
import { resetRouter } from '@/router';
var getDefaultState = function getDefaultState() {
  return {
    token: getToken(),
    name: getUserName(),
    department: '',
    departmentCode: '',
    roleName: [],
    type: ''
  };
};
var state = getDefaultState();
var mutations = {
  RESET_STATE: function RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
  SET_TOKEN: function SET_TOKEN(state, token) {
    state.token = token;
  },
  SET_NAME: function SET_NAME(state, name) {
    state.name = name;
  },
  SET_TYPE: function SET_TYPE(state, type) {
    state.type = type;
  },
  SET_DEPARTMENT: function SET_DEPARTMENT(state, department) {
    state.department = department;
  },
  SET_DEPARTMENTCODE: function SET_DEPARTMENTCODE(state, departmentCode) {
    state.departmentCode = departmentCode;
  },
  SET_ROLENAME: function SET_ROLENAME(state, roleName) {
    state.roleName = roleName;
  }
};
var actions = {
  // user login
  login: function login(_ref, userInfo) {
    var commit = _ref.commit;
    var account = userInfo.account,
      password = userInfo.password,
      smsCode = userInfo.smsCode;
    // const { account, password, verificationCode, verificationCodeToken } = userInfo;

    return new Promise(function (resolve, reject) {
      _login({
        account: account.trim(),
        password: password,
        smsCode: smsCode
        // verificationCode: verificationCode,
        // verificationCodeToken: verificationCodeToken
      }).then(function (response) {
        // console.log(response);
        var data = response.data,
          resultCode = response.resultCode,
          resultMsg = response.resultMsg;

        // 初始密码登录
        if (resultCode === 3) {
          resolve({
            resultCode: resultCode
          });
        }

        // 验证码刷新返回值
        if (resultCode === 4 || resultCode === 5) {
          resolve({
            resultCode: resultCode,
            resultMsg: resultMsg
          });
        }

        // 发送短信验证成功
        if (resultMsg === '短信已发送至您的手机') {
          resolve({
            resultCode: resultCode,
            resultMsg: resultMsg
          });
        }
        // 密码即将过期或
        if (resultCode === 11) resolve({
          resultCode: resultCode,
          pwdExpirationTime: data.pwdExpirationTime
        });
        // 密码已过期
        if (resultCode === 12) resolve({
          resultCode: resultCode
        });
        if (!data || !data.authorization) {
          reject('登录错误，请重新登录.');
        }
        var roleName = data.roleName,
          name = data.name,
          authorization = data.authorization;

        // roleName must be a non-empty array
        if (!roleName || roleName.length <= 0) {
          reject('getInfo: roleName must be a non-null array!');
        }

        // 优先执行，更新store的user信息和token信息
        commit('SET_TOKEN', authorization);
        commit('SET_NAME', name);

        // commit('SET_TYPE', type);
        // commit('SET_ROLENAME', roleName);
        // commit('SET_DEPARTMENT', department);
        // commit('SET_DEPARTMENTCODE', departmentCode);

        setToken(authorization, 0.5);
        setUserName(name);
        setLastUser(name);
        resolve({
          data: data
        });
      }).catch(function (error) {
        reject(error);
      });
    });

    /**
    const { account, password } = userInfo;
     return new Promise((resolve, reject) => {
      login({ account: account.trim(), password: password })
        .then(response => {
          const { data } = response;
          commit('SET_TOKEN', data.token);
          setToken(data.token);
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
     */
  },
  // get user info
  getInfo: function getInfo(_ref2) {
    var commit = _ref2.commit,
      state = _ref2.state;
    return new Promise(function (resolve, reject) {
      _getInfo({
        account: state.name || getLastUser()
      }).then(function (response) {
        var data = response.data;
        var details = data.details;
        if (!details || details.length <= 0) {
          reject('登录错误，请重新登录.');
        }
        // console.log(data);
        // 获取用户信息和用户信息List同接口
        var userOpt = details.filter(function (item) {
          return item.name === (state.name || getLastUser());
        });
        var _userOpt$ = userOpt[0],
          roleName = _userOpt$.roleName,
          name = _userOpt$.name,
          department = _userOpt$.department,
          departmentCode = _userOpt$.departmentCode,
          type = _userOpt$.type;

        // roleName must be a non-empty array
        if (!roleName || roleName.length <= 0) {
          reject('getInfo: roleName must be a non-null array!');
        }
        commit('SET_NAME', name);
        commit('SET_ROLENAME', roleName);
        commit('SET_DEPARTMENT', department);
        commit('SET_DEPARTMENTCODE', departmentCode);
        commit('SET_TYPE', type);
        setUserName(name);
        setLastUser(name);
        resolve(userOpt[0]);
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // user logout
  logout: function logout(_ref3) {
    var commit = _ref3.commit,
      state = _ref3.state,
      dispatch = _ref3.dispatch;
    return new Promise(function (resolve, reject) {
      _logout().then(function () {
        resolve();
      }).catch(function () {
        reject();
      }).finally(function () {
        removeToken(); // must remove  token  first
        removeUserName();
        removeFirstLogin();
        resetRouter();
        commit('RESET_STATE');
        removeLastUser();
        sessionStorage.removeItem('workPaginationOpt');
        sessionStorage.removeItem('userPaginationOpt');

        // console.log(storage.get(AD_TOKEN));
        if (storage.get(AD_TOKEN)) {
          storage.remove(AD_TOKEN);
          dispatch('msal/signOut', null, {
            root: true
          });
          return;
        }
      });
    });
  },
  // remove token
  resetToken: function resetToken(_ref4) {
    var commit = _ref4.commit;
    return new Promise(function (resolve) {
      removeToken(); // must remove  token  first
      removeUserName();
      removeFirstLogin();
      commit('RESET_STATE');
      commit('msal/SET_ADTOKEN', undefined, {
        root: true
      });
      removeLastUser();
      resolve();
    });
  } // reset state
  // resetState({ commit }) {
  //   return new Promise((resolve) => {
  //     console.log(5555, commit);
  //     removeUserName();
  //     resetRouter();
  //     commit('RESET_STATE');
  //     resolve();
  //   });
  // },
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};